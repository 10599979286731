<template>
  <section class="section container">
    <div class="box">
      <b-collapse
        v-if="isAuth && data && data[0]"
        class="card m-b-md"
        :open="false"
        aria-id="filterContent"
      >
        <div
          slot="trigger"
          slot-scope="props"
          class="card-header"
          role="button"
          aria-controls="filterContent"
        >
          <p class="card-header-title">Meus casos</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
          </a>
        </div>
        <div class="card-content">
          <b-table
            :data="data"
            :paginated="false"
            current-page.sync="1"
            :pagination-simple="false"
            default-sort-direction="asc"
            default-sort="createdAt"
            detail-key="_id"
            :show-detail-icon="true"
          >
            <template slot-scope="props">
              <b-table-column field="user.name" label="Número do caso" sortable>
                <span class="link-custom" @click="getQuestById(props.row)">{{
                  props.row.callNumber
                }}</span>
              </b-table-column>

              <b-table-column field="user.name" label="Status" sortable>{{
                props.row.status
              }}</b-table-column>

              <b-table-column
                field="user.name"
                label="Data da abertura"
                sortable
                >{{
                  new Date(props.row.createdAt).toLocaleDateString('pt-BR')
                }}</b-table-column
              >

              <b-table-column label="Alterar status">
                <button
                  @click="status('Aberto', props.row._id)"
                  v-if="props.row.status === 'Fechado'"
                  class="button is-primary"
                >
                  Reabrir caso
                </button>
                <button
                  @click="status('Fechado', props.row._id)"
                  v-else
                  class="button is-primary"
                >
                  Fechar caso
                </button>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </b-collapse>

      <div class="custom-col column is-5">
        <form @submit.prevent="contact">
          <b-field label="Nome">
            <b-input type="text" v-model="user.name" required></b-input>
          </b-field>

          <b-field label="Login" v-if="!isAuth()">
            <b-input type="text" v-model="user.user"></b-input>
          </b-field>

          <b-field label="Categoria">
            <b-select
              placeholder="Selecione uma opção"
              v-model="user.category"
              required
            >
              <option>Dúvida</option>
              <option>Sugestão</option>
              <option>Erro</option>
            </b-select>
          </b-field>

          <b-field label="Curso" v-if="courses && courses[0]">
            <b-select
              placeholder="Selecione uma opção"
              v-model="user.course"
              required
            >
              <option
                :value="course._id"
                v-for="course in courses"
                :key="course._id"
                >{{ course.name }}</option
              >
            </b-select>
          </b-field>

          <b-field label="Email">
            <b-input type="email" v-model="user.email" required></b-input>
          </b-field>

          <b-field label="Mensagem">
            <b-input type="textarea" v-model="user.message" required></b-input>
          </b-field>

          <button class="button is-info is-fullwidth">Enviar</button>
        </form>
      </div>
    </div>

    <b-modal :active.sync="showModal2" :width="740" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <form>
              <b-field label="Mensagem">
                <b-input v-model="editModule.message" disabled></b-input>
              </b-field>

              <div class="box">
                <div
                  v-show="answer && answer[0]"
                  v-for="v in answer"
                  :key="v._id"
                >
                  <div>
                    <b class="m-r-sm"
                      >{{ me._id === v.user._id ? 'Eu' : 'Suporte Assban' }}:</b
                    >
                    <span>{{ v.message }}</span>
                  </div>
                  <span>
                    <b>Data:</b>
                    {{
                      new Date(v.createdAt).toLocaleDateString('pt-BR') + ' '
                    }}
                  </span>
                  <span>{{
                    new Date(v.createdAt).toLocaleTimeString('pt-BR')
                  }}</span>
                  <hr />
                </div>
                <p v-show="!answer || !answer[0]">Sem respostas</p>
              </div>

              <b-field
                label="Enviar mensagem"
                v-if="
                  editModule.status === 'Aberto' ||
                    editModule.status === 'Em atendimento'
                "
              >
                <b-input
                  v-model="editModule.messageResponse"
                  type="textarea"
                ></b-input>
              </b-field>

              <button
                v-if="
                  editModule.status === 'Aberto' ||
                    editModule.status === 'Em atendimento'
                "
                class="button is-info m-t-md"
                type="button"
                @click="update"
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Axios from 'axios';
import { isAuth } from '@/global/auth';

export default {
  data() {
    return {
      courses: [],
      editModule: [],
      data: [],
      answer: [],
      clear: null,
      showModal2: null,
      me: [],
      user: {
        course: null,
        name: '',
        email: '',
        category: null,
        message: '',
        user: '',
      },

      isAuth,
    };
  },
  methods: {
    edit(data) {
      this.editModule = data;
      this.showModal2 = true;
    },
    contact() {
      if (!this.user.user) delete this.user.user;

      Axios.post('/v1/contacts', this.user)
        .then((data) => {
          if (data.data) {
            this.user = {};
            this.$buefy.notification.open({
              message: 'Menssagem enviada com sucesso!',
              type: 'is-success',
            });
          }
        })
        .catch((err) => {
          console.error(err);

          for (const e of err.response.data.error) {
            if (
              e.message[0].includes('email') &&
              e.message[0].includes('must be')
            ) {
              this.$buefy.dialog.alert('Email inválido!');
              this.erroForm.cpf = 'is-danger';
            }
          }
        });
    },
    getMe() {
      if (isAuth()) {
        Axios.get('/v1/users/me')
          .then((data) => {
            if (data.data) {
              this.me = data.data;
              this.user.email = data.data.email;
              this.user.user = data.data._id;
              this.getContact();
            }
          })
          .catch((erro) => {
            console.error(erro);
          });
      }
    },
    getCourse() {
      Axios.get('/v1/courses/info?active=true')
        .then((data) => {
          if (data.data) {
            this.courses = data.data.data;
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getContact() {
      this.data = [];
      Axios.get(`/v1/contacts?user=${this.me._id}`)
        .then((data) => {
          if (data.data) {
            this.data = data.data.data;
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getQuestById(props) {
      Axios.get(`/v1/contact-answers?contact=${props._id}`)
        .then((data) => {
          if (data.data) {
            this.answer = data.data.data;
            this.edit(props);
          }
        })
        .catch((erro) => {
          console.error(erro);
          this.answer = [];
          this.edit(props);
        });
    },
    update() {
      Axios.post(`/v1/contact-answers/`, {
        contact: this.editModule._id,
        message: this.editModule.messageResponse,
        course: this.editModule.course,
        user: this.me._id,
      })
        .then((data) => {
          if (data.data) {
            this.getContact();
            this.showModal2 = false;
            this.$buefy.notification.open({
              message: 'Mensagem enviada com sucesso!',
              type: 'is-success',
            });
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    status(status, id) {
      Axios.put(`/v1/contacts/${id}`, {
        status: status,
      })
        .then(() => {
          this.getContact();
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
  },
  mounted() {
    this.getMe();
    this.getCourse();
  },
};
</script>

<style lang="scss">
.custom-col {
  margin: 0 auto;
}

.link-custom {
  color: rgb(81, 175, 230);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
